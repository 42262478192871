<script>
import {userdata, levelsStore} from '../js/stores.js';
import page from "page";

export let data;
export let anchor;
let logged;
let clicked=false;
let levels = {};
const formatNumber = new Intl.NumberFormat('ru-RU').format;
userdata.subscribe(value => logged = (value != null));
levelsStore.subscribe(value => levels = value);

function toggleFavorite() {
    data.favorite = !data.favorite;
    fetch("/server/setFavorite?val="+data.favorite+"&gameId="+data.id);
}
</script>

{#if data.outer_uri}
<div class="game-card-item">
    <div class="img-wrapper card-preview">
        <img src="/images/game-card/{data.outer_uri.toLowerCase()}.jpg" alt="game-preview" class="game-preview">
        {#if data.is_new}
        <span class="new-label">New</span>
        {/if}
        <a class="game-group" href={null} on:click={() => {
            page("/slots/"+data.producer);
            window.scrollTo({top: anchor.offsetTop, behavior: 'smooth'});
        }}>{data.producer_name}</a>
        <a class="play-btn img-wrapper" href={null} on:click={() => {if (!clicked) page(["", data.type+"s", data.producer, data.uri].join('/'))}}>
            <img src="/images/game-card/play-btn.svg" alt="play-btn" class="play-btn-img">
            <a class="play-btn-demo" href={null} on:click={() => {clicked=true; page(["", data.type+"s", data.producer, data.uri, "demo"].join('/'))}}><span>DEMO</span></a>
        </a>
        {#if logged}
        <a href={null} class="like-block" class:is-on={data.favorite} on:click={toggleFavorite}>
            <div class="img-wrapper like-icon">
                <img src="/images/game-card/like-icon.svg" alt="like-icon">
            </div>
        </a>
        {/if}
        {#if data.username}
        <div class="gamer-group">
            <div class="img-wrapper gamer-group__gamer-icon">
                <img src="/images/levels/{levels[data.level??1].pic}" alt="gamer">
            </div>
            <p class="gamer-group__name">{data.username}</p>
        </div>
        <div class="total-money">
            <span>{data.currency=='RUB'?'₽':'$'} {formatNumber(Math.round(data.win/100))}</span>
        </div>
        {/if}
    </div>
</div>
{/if}